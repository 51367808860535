@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif
}



.pic {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.table thead {
    background-color: #1a171c;
}

.table thead th {
    padding: 30px;
    font-size: 14px;
    color: white;
}

.table tbody td input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #cbcbcb;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
}

.container .table-wrap {

    margin: 20px auto;
    overflow-x: auto
}

.container .table-wrap::-webkit-scrollbar {
    height: 5px;
}

.container .table-wrap::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: linear-gradient(to right, #5D7ECD, #0C91E6);
}

.table>:not(caption)>*>* {
    padding: 2rem 0.5rem;
}

.input {
    width: 30px;
    height: 30px;
    color: black;
    font-weight: 600;
    outline: none;
    padding: 8px;
}

::placeholder {
    color: black;
    font-weight: 600;
}

.table tbody td input[type="checkbox"]:after {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f00c";
    color: #000;
    font-size: 15px;
    display: none
}

.table tbody td input[type="checkbox"]:checked,
.table tbody td input[type="checkbox"]:checked:hover {
    background-color: #00d8ff;
}

.table tbody td input[type="checkbox"]:checked::after {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table tbody td input[type="checkbox"]:hover {
    background-color: #ddd;
}

.table tbody td {
    padding: 30px;
    margin: 0;
    font-size: 14.5px;
    font-weight: 600;
}

.table tbody td .fa-times {
    color: #D32F2F;
}


.text-muted {
    font-size: 12.5px;
}

.table tbody tr td:nth-of-type(3) {
    min-width: 320px;
}

@media(min-width: 992px) {
    .container .table-wrap {
        overflow: hidden;
    }
}