.container.cards{
    width: 1200px !important;
    padding: 0 !important;
    margin-right: auto;
    margin-left: auto;
  
    @media screen and (min-width: 992px) and (max-width: 1439px) {
      max-width: 1279px !important;
      padding: 0 !important;
      margin: 0 80px !important;
      width: auto !important;
    }
  
    @media screen and (max-width: 991px) {
      max-width: 959px !important;
      margin: 0 16px !important;
      padding: 0 !important;
      width: auto !important;
    }
  }
  
  .gradient-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 30px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  
  .container-title {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 60px;
  }
  
  .card {
    max-width: 550px;
    border: 0;
    width: 100%;
    margin-inline: auto;
  }
  
  .container-card {
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(71deg, #080509, #1a171c, #080509);
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
}
/* img {
  margin-bottom: 32px;
} */
  
  .bg-green-box,
  .bg-white-box,
  .bg-yellow-box,
  .bg-blue-box {
    position: relative;
  }
  
  .bg-green-box::after,
  .bg-white-box::after,
  .bg-yellow-box::after,
  .bg-blue-box::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    border-radius: 45px;
  }
  
  .bg-green-box::after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
  }
  
  .bg-white-box::after {
    background: linear-gradient(71deg, #121013, #b0afb0, #121013);
  }
  
  .bg-yellow-box::after {
    background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
  }
  
  .bg-blue-box::after {
    background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
  }
  
  .card-title {
    font-weight: 600;
    
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 28px;
    /* padding-bottom: 8px; */
    margin-top: 10px;
  }
  .donor-count {
    border: 2px solid red;
    width: auto;
    border-radius: 20px;
    /* padding: 10px 4px 1px 2px; */
    padding: 10px 15px 0px 15px;
    color: red;
}
.request-count {
  border: 2px solid #1565c0;
  width: auto;
  border-radius: 20px;
  /* padding: 10px 4px 1px 2px; */
  padding: 10px 15px 0px 15px;
  color: #1565c0;
}
h1.shadow {
  font-weight: bold;
  text-shadow: 1px 4px 2px black, 0 0 1em blue, 0 0 0.2em blue;
  color: #1565c0;
  /* font:
    1.5em Georgia,
    serif; */
}
h1.shadow2 {
  font-weight: bold;
  text-shadow: 1px 4px 2px black, 0 0 1em #c62020, 0 0 0.2em #c62020;
  color: #ea062b;
  /* font:
    1.5em Georgia,
    serif; */
}
  .card-description {
    font-weight: 600;
    line-height: 32px;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 16px;
    max-width: 470px;
  }
  